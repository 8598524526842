import _slicedToArray from"@babel/runtime/helpers/esm/slicedToArray";import"core-js/modules/es.array.find.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.object.keys.js";import"core-js/modules/es.array.index-of.js";import"core-js/modules/es.array.iterator.js";import"core-js/modules/es.promise.js";import"core-js/modules/es.string.iterator.js";import"core-js/modules/web.dom-collections.iterator.js";import"core-js/modules/es.regexp.exec.js";import"core-js/modules/es.regexp.test.js";import"core-js/modules/es.array.includes.js";import"core-js/modules/es.string.includes.js";import"core-js/modules/es.string.match.js";import*as BuildConfig from'soapbox/build-config';var SentryEnvironmentMap={'truthsocial.com':'production','01cxhdz3a8jnmapv.com':'staging'};var sentryEnvironment=BuildConfig.NODE_ENV==='production'&&Object.keys(SentryEnvironmentMap).find(function(item){return window.location.hostname.indexOf(item)>=0;});var denyUrls=[// Browser extensions.
/extensions\//i,/^chrome:\/\//i,/^chrome-extension:\/\//i,/^moz-extension:\/\//i,// Embedded pages.
/^.*\/embed.*$/i];export var start=function start(){Promise.all([import(/* webpackChunkName: "error" */'@sentry/react')]).then(function(_ref){var _ref2=_slicedToArray(_ref,1),Sentry=_ref2[0];Sentry.init({dsn:BuildConfig.SENTRY_DSN,environment:sentryEnvironment?SentryEnvironmentMap[sentryEnvironment]:undefined,debug:false,enabled:!!sentryEnvironment,release:process.env.CI_COMMIT_SHA,replaysSessionSampleRate:0.1,replaysOnErrorSampleRate:1.0,integrations:[Sentry.replayIntegration(),Sentry.browserTracingIntegration()],beforeBreadcrumb:function beforeBreadcrumb(breadcrumb){var _breadcrumb$data,_breadcrumb$data2,_breadcrumb$data2$url;// Ignore fetches to Tabler Icons
var iconRegex=/^\/packs\/icons\/[\w-]+\.svg$/;if(breadcrumb.category==='fetch'&&iconRegex.test((_breadcrumb$data=breadcrumb.data)===null||_breadcrumb$data===void 0?void 0:_breadcrumb$data.url)){return null;}// Ignore Mixpanel tracking + engaging
if(breadcrumb.category==='xhr'&&(_breadcrumb$data2=breadcrumb.data)!==null&&_breadcrumb$data2!==void 0&&(_breadcrumb$data2$url=_breadcrumb$data2.url)!==null&&_breadcrumb$data2$url!==void 0&&_breadcrumb$data2$url.includes('https://api-js.mixpanel.com')){return null;}return breadcrumb;},beforeSend:function beforeSend(event){var _event$exception,_event$exception$valu;// If the error comes through via the CaptureConsole integration,
// the "denyUrls" config option is ignored. We need to manually
// filter these out.
// Ref: https://github.com/getsentry/sentry-javascript/issues/5560
var isDenied=denyUrls.some(function(url){var _event$request,_event$request$url;return!!((_event$request=event.request)!==null&&_event$request!==void 0&&(_event$request$url=_event$request.url)!==null&&_event$request$url!==void 0&&_event$request$url.match(url));});if(isDenied){return null;}/**
         * Ignore errors that come from external scripts.
         */var ignoredFilenames=['pagead/js/adsbygoogle.js','tv2track.js'];if((_event$exception=event.exception)!==null&&_event$exception!==void 0&&(_event$exception$valu=_event$exception.values)!==null&&_event$exception$valu!==void 0&&_event$exception$valu.some(function(value){var _value$stacktrace,_value$stacktrace$fra;return(_value$stacktrace=value.stacktrace)===null||_value$stacktrace===void 0?void 0:(_value$stacktrace$fra=_value$stacktrace.frames)===null||_value$stacktrace$fra===void 0?void 0:_value$stacktrace$fra.some(function(frame){return ignoredFilenames.some(function(ignoredFilename){var _frame$filename;return(_frame$filename=frame.filename)===null||_frame$filename===void 0?void 0:_frame$filename.includes(ignoredFilename);});});})){return null;// Ignore the error
}return event;},// Filter events.
// https://docs.sentry.io/platforms/javascript/configuration/filtering/
ignoreErrors:[// See: https://github.com/getsentry/sentry-javascript/issues/3440
'Non-Error exception captured','Non-Error promise rejection captured',// Virtuoso quirk with no user impact
/ResizeObserver loop/i,// Network errors.
/AxiosError/i,// sw.js couldn't be downloaded.
'Failed to update a ServiceWorker for scope',// Useful for try/catch, useless as a Sentry error.
'AbortError',// localForage error in FireFox private browsing mode (which doesn't support IndexedDB).
// We only use IndexedDB as a cache, so we can safely ignore the error.
'No available storage method found',// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
'originalCreateNotification','canvas.contentDocument','Could not parse the above streaming event.','[object MessageEvent]'],denyUrls:denyUrls});}).catch(console.error);};export var captureException=function captureException(exception,captureContext){import(/* webpackChunkName: "error" */'@sentry/react').then(function(Sentry){return Sentry.captureException(exception,captureContext);}).catch(console.error);};export var setUserToMonitor=function setUserToMonitor(user){import(/* webpackChunkName: "error" */'@sentry/react').then(function(Sentry){return Sentry.setUser(user);}).catch(console.error);};