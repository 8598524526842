import _toConsumableArray from"@babel/runtime/helpers/esm/toConsumableArray";import"core-js/modules/es.array.find.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.array.index-of.js";import"core-js/modules/es.array.unshift.js";import"core-js/modules/es.array.splice.js";import"core-js/modules/es.array.concat.js";import"core-js/modules/es.json.stringify.js";import sumBy from'lodash/sumBy';import{queryClient,QueryKeys}from'soapbox/api/core';import{chatMessageSchema}from'soapbox/schemas';import{chatSearchHistory}from'soapbox/settings';import{compareDate}from'./comparators';import{appendPageItem,flattenPages,removePageItem,sortQueryData,updatePageItem}from'./queries';var RECENT_SEARCHES_KEY='soapbox:recent-chat-searches';/**
 * Update the Chat entity inside the ChatSearch query.
 * @param newChat - Chat entity.
 */var updateChatInChatSearchQuery=function updateChatInChatSearchQuery(newChat){updatePageItem(QueryKeys.chats.list(),newChat,function(o,n){return o.id===n.id;});};/**
 * Re-order the ChatSearch query by the last message timestamp.
 */var reOrderChatListItems=function reOrderChatListItems(){sortQueryData(QueryKeys.chats.list(),function(chatA,chatB){var _chatA$last_message,_chatB$last_message;return compareDate((_chatA$last_message=chatA.last_message)===null||_chatA$last_message===void 0?void 0:_chatA$last_message.created_at,(_chatB$last_message=chatB.last_message)===null||_chatB$last_message===void 0?void 0:_chatB$last_message.created_at);});};/**
 * Check if a Chat entity exists within the cached ChatSearch query.
 * @param chatId - String
 * @returns Boolean
 */var checkIfChatExists=function checkIfChatExists(chatId){var currentChats=flattenPages(queryClient.getQueryData(QueryKeys.chats.list()));return currentChats===null||currentChats===void 0?void 0:currentChats.find(function(chat){return chat.id===chatId;});};/**
 * Force a re-fetch of ChatSearch.
 */var invalidateChatSearchQuery=function invalidateChatSearchQuery(){queryClient.invalidateQueries(QueryKeys.chats.list());};var updateChatListItem=function updateChatListItem(newChat){var chatId=newChat.id,lastMessage=newChat.last_message;var isChatAlreadyLoaded=checkIfChatExists(chatId);if(isChatAlreadyLoaded){// If the chat exists in the client, let's update it.
updateChatInChatSearchQuery(newChat);// Now that we have the new chat loaded, let's re-sort to put
// the most recent on top.
reOrderChatListItems();}else{// If this is a brand-new chat, let's invalid the queries.
invalidateChatSearchQuery();}if(lastMessage){// Update the Chat Messages query data.
appendPageItem(QueryKeys.chatMessages.forChat(newChat.id),chatMessageSchema.parse(lastMessage));}};/** Get unread chats count. */var getUnreadChatsCount=function getUnreadChatsCount(){var chats=flattenPages(queryClient.getQueryData(QueryKeys.chats.list()));return sumBy(chats,function(chat){return chat.unread;});};/** Update the query cache for an individual Chat Message */var updateChatMessage=function updateChatMessage(chatMessage){return updatePageItem(QueryKeys.chatMessages.forChat(chatMessage.chat_id),chatMessageSchema.parse(chatMessage),function(o,n){return o.id===n.id;});};var clearRecentSearches=function clearRecentSearches(currentUserId){return chatSearchHistory.remove(currentUserId);};var saveChatSearch=function saveChatSearch(currentUserId,search){var currentSearches=[];if(chatSearchHistory.get(currentUserId)){currentSearches=chatSearchHistory.get(currentUserId);}if(currentSearches.indexOf(search)===-1){currentSearches.unshift(search);if(currentSearches.length>10){currentSearches.pop();}chatSearchHistory.set(currentUserId,currentSearches);return currentSearches;}else{// The search term has already been searched. Move it to the beginning
// of the cached list.
var indexOfSearch=currentSearches.indexOf(search);var nextCurrentSearches=_toConsumableArray(currentSearches);nextCurrentSearches.splice.apply(nextCurrentSearches,[0,0].concat(_toConsumableArray(nextCurrentSearches.splice(indexOfSearch,1))));localStorage.setItem(RECENT_SEARCHES_KEY,JSON.stringify(nextCurrentSearches));return nextCurrentSearches;}};/** Check if item is most recent */var isLastMessage=function isLastMessage(chatMessageId){var queryData=queryClient.getQueryData(QueryKeys.chats.list());var items=flattenPages(queryData);var chat=items===null||items===void 0?void 0:items.find(function(item){var _item$last_message;return((_item$last_message=item.last_message)===null||_item$last_message===void 0?void 0:_item$last_message.id)===chatMessageId;});return!!chat;};var removeChatMessage=function removeChatMessage(payload){var data=JSON.parse(payload);var chatId=data.chat_id;var chatMessageId=data.deleted_message_id;// If the user just deleted the "last_message", then let's invalidate
// the Chat Search query so the Chat List will show the new "last_message".
if(isLastMessage(chatMessageId)){queryClient.invalidateQueries(QueryKeys.chats.list());}removePageItem(QueryKeys.chatMessages.forChat(chatId),chatMessageId,function(o,n){return String(o.id)===String(n);});};export{clearRecentSearches,getUnreadChatsCount,reOrderChatListItems,saveChatSearch,updateChatListItem,updateChatMessage,isLastMessage,removeChatMessage};